//TABS//
function openGame(evt, gameName) {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tab__content");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.opacity = "0";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(gameName).style.opacity = "1";
  document.getElementById(gameName).style.zIndex = "10";
  evt.currentTarget.className += " active";
}

$(document).ready(function () {
  $(".brands__slider").slick({
    slidesToShow: 6,
    arrows: false,
    autoplay: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1081,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 811,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
  $(".portrait__slider").slick({
    slidesToShow: 6,
    arrows: false,
    autoplay: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1081,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 811,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
  $(".events__slider").slick({
    slidesToShow: 6,
    arrows: false,
    autoplay: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1081,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 811,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
});

// Anchor Scrolling
function initAnchorScrolling() {
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $("html, body").animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });
}

$(document).ready(function () {
  initAnchorScrolling();
});
